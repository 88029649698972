export enum LoginType {
  PRIVATE = 'private',
  BUSINESS = 'business',
  UNDEFINED = 'undefined',
}

export enum Banks {
  SYDBANK = 1,
  NORDFYNSBANK = 8,
  SKJERNBANK = 9,
  DJURSLANDSBANK = 13,
  KREDITBANKEN = 15,
  LANDBOBANKEN = 24,
  SPKS = 42,
  SYDBANKTYSKLAND = 47,
  JYSKEBANK = 51,
  BANKDATA = 300,
}

export enum Nav {
  CPR = 'cpr',
  LOGON = 'logon',
  REDIRECT = 'redirect',
  ORDERS = 'orders',
}
