import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CprComponent } from './modules/cpr/cpr.component';
import { LogonComponent } from './modules/logon/logon.component';
import { OrdersComponent } from './modules/orders/orders.component';
import { RedirectComponent } from './modules/redirect/redirect.component';
import {AuthGuard} from './services/auth.guard';
import {PostMessageGuard} from "./services/post-message.guard";

const routes: Routes = [
  { path: 'logon', component: LogonComponent },
  { path: 'cpr', component: CprComponent },
  { path: 'redirect', component: RedirectComponent },
  { path: 'orders', component: OrdersComponent, canActivate: [PostMessageGuard] },
  { path: '', pathMatch: 'full', canActivate: [AuthGuard] , component: LogonComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
