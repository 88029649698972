import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { EnvironmentService } from 'src/environments/environment.service';
import { httpHeaderSetupService } from './http-header-setup.service';
import { Observable } from 'rxjs';
import { OAuthService } from 'src/app/services/oauth/oauth.service';
import { TokenResponse } from './oauth/types';
import {Link} from '../models/link';

@Injectable({
  providedIn: 'root',
})
export class LinkService {
  private readonly ORDER_BASE_PATH: string =
    'digital-signing-customer-app-sig/';
  private readonly LINK_SERVICE_PATH: string = 'v1/orders/';
  private readonly LINK_SERVICE_SIGNERS: string = '/signers/';
  private readonly LINK_SERVICE_LINK: string = '/link';

  constructor(
    private environmentService: EnvironmentService,
    private httpClient: HttpClient,
    private httpHeaderSetup: httpHeaderSetupService,
    private oAuthService: OAuthService
  ) {}

  public getPenneoLink(orderId: number, signerId: number): Observable<Link> {
    console.log('getPenneoLink input', orderId, signerId);
    const xApiKey = this.environmentService.xApiKey;
    const url =
      this.environmentService.hostPath +
      this.ORDER_BASE_PATH +
      this.LINK_SERVICE_PATH +
      orderId +
      this.LINK_SERVICE_SIGNERS +
      signerId +
      this.LINK_SERVICE_LINK;
    // authentication (jwt) required
    console.log('getPenneoLink setup 1', url);
    const credential: TokenResponse = this.oAuthService.tokenResponse;
    const headers = this.httpHeaderSetup.getHttpHeaderOptions(
      xApiKey,
      credential.access_token,
      credential.token_type
    );
    console.log('getPenneoLink setup 2', headers);

    return this.httpClient.post<Link>(url, null, headers).pipe(
      map((res) => {
        console.log('getPenneoLink res', res);
        return res.body;
      }),
      catchError((err) => {
        return Observable.throw(err.statusText);
      })
    );
  }
}
