import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Settings } from 'luxon';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'digital-signing-customer-app';
  constructor(translate: TranslateService) {
    translate.setDefaultLang('da');
    Settings.defaultLocale = 'da';
  }

  public ngAfterViewInit() {}
}
