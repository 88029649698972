<div class="messageContainer">
  <kirby-card>
    <div class="cardContent">
      <div class="cardText">
        <p style="width: 100%; text-align: center;">{{ message }}
          <ng-container *ngIf="subMessage.length > 0">
            <br>
            {{subMessage}}</ng-container>
        </p>
      </div>
      <div *ngIf="showLoginButton" class="cardButtons">
        <button kirby-button (click)="onBackToLogon()">
          {{ loginText }}
        </button>
      </div>
    </div>
  </kirby-card>
</div>
