
export interface SignerStatus {
  /**
   * A description of the current status Enum
   */
  description: string;
  /**
   * Current signer status
   */
  status: SignerStatus.StatusEnum;
}

export namespace SignerStatus {
  export type StatusEnum = 'CREATED' | 'SIGNED' | 'REJECTED' | 'ERROR';
  export const StatusEnum = {
    CREATED: 'CREATED' as StatusEnum,
    SIGNED: 'SIGNED' as StatusEnum,
    REJECTED: 'REJECTED' as StatusEnum,
    ERROR: 'ERROR' as StatusEnum
  };
}

