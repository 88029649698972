import { Component, Inject, Input, OnInit } from '@angular/core';
import { Translations, TRANSLATIONS_TOKEN } from '../translations/translations';
import { TranslateService } from '@ngx-translate/core';
import { LinkService } from 'src/app/services/link.service';
import { ToastConfig } from '@kirbydesign/designsystem';
import { ToastController } from '@kirbydesign/designsystem';
import {Order} from '../../models/order';
import {SignerStatus} from '../../models/signerStatus';
import {Link} from '../../models/link';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})
export class OrderComponent implements OnInit{
  public linkObj: Link;
  @Input() order: Order;

  constructor(
    @Inject(TRANSLATIONS_TOKEN) public text: Translations,
    public translateService: TranslateService,
    private linkService: LinkService,
    public toastController: ToastController
  ) {}

  public deadlineText: String = '';
  public signatureDeadline: Date;

  ngOnInit(): void {
    this.signatureDeadline = new Date(this.order.signatureDeadline)
    this.deadlineText = this.translateService.instant(this.text.orders.signatureDeadline)
  }

  public onSignOrder() {
    this.linkService
      .getPenneoLink(this.order.orderId, this.order.orderSigner.signerId)
      .subscribe({
        next: (result: Link) => {
          this.linkObj = result;
          console.log('order component link', result, this.linkObj.link);
          document.location.href = this.linkObj.link;
        },
        error: (error) => {
          const config: ToastConfig = {
            message: this.translateService.instant(this.text.orders.error),
            messageType: 'warning',
            durationInMs: 4000,
          };
          this.toastController.showToast(config);
        },
      });
  }

  public isAwaitingSigning() : boolean {
    return this.order.orderSigner.status.status == SignerStatus.StatusEnum.CREATED
  }

  public signableDocumentsCount(){
    let count = 0;
    for (const document of this.order.orderDocuments) {
      if(document.signatureRequired){
        count++;
      }
    }

    return count;
  }

  public documentText(singularNumber?: boolean, altSingular?: string) {
    const count = this.signableDocumentsCount();
    let singular = " dokument"
    let plural = " dokumenter"

    if(altSingular != null) {
      singular = altSingular;
    }

    if(count > 1) {
      return count + plural;
    }

    if(singularNumber != null && singularNumber){
      return count +  singular;
    }

    return singular;
  }

  getTrueIndex(index: number) {
    let trueIndex = 0;
    for (let i = 0; i <= index; i++) {
      const document = this.order.orderDocuments[i];
      if(document.signatureRequired){
        trueIndex++;
      }
    }
    return trueIndex;
  }

  getButtonText() {
    if(this.order.orderSigner.status.status == SignerStatus.StatusEnum.CREATED){
      return this.translateService.instant(this.text.orders.button.created)
    } else if(this.order.orderSigner.status.status == SignerStatus.StatusEnum.SIGNED){
      return this.translateService.instant(this.text.orders.button.signed)
    }else if(this.order.orderSigner.status.status == SignerStatus.StatusEnum.REJECTED){
      return this.translateService.instant(this.text.orders.button.rejected)
    }
    return ""
  }

  getButtonTitle() {
    if (this.order.orderSigner.status.status == SignerStatus.StatusEnum.SIGNED) {
      if(this.order.hasMultipleSigners) {
        return this.translateService.instant(this.text.orders.button.signedExtMultiple)
      }
      return this.translateService.instant(this.text.orders.button.signedExt)
    }
    return ""
  }

  getCvrText() {
    if(this.order.orderSigner.cvrNo == undefined){
      return "";
    }

    let cvrText = "som ";
    if(this.order.orderSigner.signerRole != null) {
      cvrText += this.order.orderSigner.signerRole.description;
    } else {
      cvrText += "Tegningsberettiget";
    }

    cvrText += " på vegne af virksomhed "

    if(this.order.orderSigner.companyName != null)
    {
      cvrText += this.order.orderSigner.companyName  +" (" +  this.order.orderSigner.cvrNo + ")";
    } else {
     cvrText +=  "CVR-nr: " + this.order.orderSigner.cvrNo;
    }

    return cvrText;
  }
}
