import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import {OAuthService} from './oauth/oauth.service';
import {LoginType, Nav} from '../utils/enums';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private oAuthService: OAuthService,
              private router: Router) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if ( await this.oAuthService.loadDiscoveryDocumentAndTryLogin()) {
      this.router.navigate([Nav.CPR]).then();
      return true;
    } else {
      this.oAuthService.setLoginType(LoginType.PRIVATE);
      this.oAuthService.initCodeFlow();
      return false;
    }
  }
}
