import {Component, Inject, OnInit} from '@angular/core';
import {OrderService} from 'src/app/services/order.service';
import {Translations, TRANSLATIONS_TOKEN} from '../translations/translations';
import {OAuthService} from 'src/app/services/oauth/oauth.service';
import {Nav} from '../../utils/enums';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ToastConfig, ToastController} from '@kirbydesign/designsystem';
import {Order} from '../../models/order';
import {SignerStatus} from '../../models/signerStatus';
import {EnvironmentService} from '../../../environments/environment.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {
  public orders: Array<Order> = [];
  public ordersFetched: boolean = false;
  public ordersFailed: boolean = false;
  public ordersFailedText: string = '';
  public noOrderText: string = '';
  public noOrderSubText: string = '';
  public logOutText: string = '';

  public awaitingSignatureText: string = '';
  public signedText: string = '';
  public rejectedText: string = '';

  private signedToast: ToastConfig;
  private rejectedToast: ToastConfig;

  constructor(
    private oAuthService: OAuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private orderService: OrderService,
    @Inject(TRANSLATIONS_TOKEN) public text: Translations,
    public translateService: TranslateService,
    public toastController: ToastController,
    private environmentService: EnvironmentService
  ) {
  }

  ngOnInit(): void {
    const isLoggedIn = this.oAuthService.isLoggedIn;
    console.log("orders isLoggedIn " + isLoggedIn)

    if (!isLoggedIn) {
      this.router.navigate([Nav.LOGON]);
      return;
    }

    let signed = this.activatedRoute.snapshot.queryParams.signed;
    let rejected = this.activatedRoute.snapshot.queryParams.rejected;
    let signerId = this.activatedRoute.snapshot.queryParams.signerId

    // cleanup query params
    this.router.navigate([], {}).then();

    this.initTranslations();
    this.orderService.getOrders().subscribe({
      next: (orders) => {
        this.ordersFetched = true;
        if (orders.length > 0) {

          orders.sort((a, b) => <any>new Date(b.created) - <any>new Date(a.created))
          for (const order of orders) {
            if (signed == order.orderId && signerId == order.orderSigner.signerId) {
              order.orderSigner.status.status = SignerStatus.StatusEnum.SIGNED
              this.toastController.showToast(this.signedToast);
            }
            if (rejected == order.orderId && signerId == order.orderSigner.signerId) {
              order.orderSigner.status.status = SignerStatus.StatusEnum.REJECTED
              this.toastController.showToast(this.rejectedToast);
            }
          }

          this.orders = orders;
        } else {
          this.noOrderText = this.translateService.instant(this.text.orders.noOrders);
          this.noOrderSubText = this.translateService.instant(this.text.orders.questions) + " " + this.environmentService.bankName + ".";
          this.logOutText = this.translateService.instant(
            this.text.common.logout
          );
        }
      },
      error: (error) => {
        console.log(error);
        this.ordersFailed = true;
        this.ordersFailedText = this.translateService.instant(
          this.text.orders.error
        );
      },
    });
  }

  private initTranslations() {
    this.awaitingSignatureText = this.translateService.instant(
      this.text.orders.status.awaitingSignature
    );

    this.signedText = this.translateService.instant(
      this.text.orders.status.signed
    );

    this.rejectedText = this.translateService.instant(
      this.text.orders.status.rejected
    );

    this.signedToast = {
      message: this.translateService.instant(this.text.orders.signed),
      messageType: 'success',
      durationInMs: 4000,
    };

    this.rejectedToast = {
      message: this.translateService.instant(this.text.orders.rejected),
      messageType: 'warning',
      durationInMs: 4000,
    };
  }
}
