import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { EnvironmentService } from 'src/environments/environment.service';
import { httpHeaderSetupService } from './http-header-setup.service';
import { Observable } from 'rxjs';
import { OAuthService } from 'src/app/services/oauth/oauth.service';
import { TokenResponse } from './oauth/types';
import {Order} from '../models/order';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private readonly ORDER_BASE_PATH: string =
    'digital-signing-customer-app-sig/';
  private readonly ORDER_SERVICE_PATH: string = 'v1/orders/';

  constructor(
    private environmentService: EnvironmentService,
    private httpClient: HttpClient,
    private httpHeaderSetup: httpHeaderSetupService,
    private oAuthService: OAuthService
  ) {}

  public getOrders(): Observable<Order[]> {
    const xApiKey = this.environmentService.xApiKey;
    const url =
      this.environmentService.hostPath +
      this.ORDER_BASE_PATH +
      this.ORDER_SERVICE_PATH;
    // authentication (jwt) required
    const credential: TokenResponse = this.oAuthService.tokenResponse;
    const headers = this.httpHeaderSetup.getHttpHeaderOptions(
      xApiKey,
      credential.access_token,
      credential.token_type
    );

    return this.httpClient.get<Order[]>(url, headers).pipe(
      map((res) => {
        return res.body;
      }),
      catchError((err) => {
        console.log(err)
        return Observable.throw(err.statusText);
      })
    );
  }
}
