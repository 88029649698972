<div class="loginCard">
  <kirby-card>
    <div class="cardContent">
      <div class="cardText">
        <p>{{ text.login.text | translate }}</p>
      </div>
      <div class="cardButtons">
        <button kirby-button (click)="onPrivateLogin()">
          {{ text.login.private | translate }}
        </button>
<!--        <button kirby-button (click)="onBusinessLogin()">-->
<!--          {{ text.login.business | translate }}-->
<!--        </button>-->
      </div>
    </div>
  </kirby-card>
</div>
