<div class="ordersContainer">
  <h1>{{ text.orders.title | translate }}</h1>
  <!-- Default icons -->

  <!--Orders are still being fetched-->
  <ng-container *ngIf="!ordersFetched && !ordersFailed">
    <kirby-spinner></kirby-spinner>
  </ng-container>
  <!--Orders was fetched successfully-->
  <ng-container *ngIf="ordersFetched && !ordersFailed">
    <ng-container *ngIf="orders.length === 0">
      <app-message
        [message]="noOrderText"
        [subMessage]="noOrderSubText"
        [showLoginButton]="true"
        [backToLoginText]="logOutText"
      ></app-message>
    </ng-container>

    <kirby-list [hasItemSpacing]="true"
                [items]="orders"
    >
      <app-order *kirbyListItemTemplate="let order, let index = index" [order]="order"></app-order>
    </kirby-list>
  </ng-container>
  <!--Fetch of orders failed-->
  <ng-container *ngIf="ordersFailed">
    <app-message [message]="ordersFailedText"></app-message>
  </ng-container>
</div>
