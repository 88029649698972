/* eslint-disable no-useless-escape */
import { Injectable } from '@angular/core';

@Injectable()
export class Patterns {
  public cprMask = '000000q';
  public cprMaskFull = '000000-qqqq';
  public cprPattern = '[0-9X]{6}[0-9X]{1}';
  public cprPatternFull = '[0-9X]{6}[0-9X]{4}';
  public cprPatterns: any = {
    q: { pattern: new RegExp('[0-9X]') },
    0: { pattern: new RegExp('[0-9]') },
  };
}
