import { Injectable } from '@angular/core';
import { environment } from './environment';
import { EnvironmentInterface } from './environment.interface';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService implements EnvironmentInterface {
  constructor() {
    // empty
  }

  public get production(): boolean {
    return environment.production;
  }

  public get environment(): string {
    return environment.environment;
  }

  public get xApiKey(): string {
    return environment.xApiKey;
  }

  public get hostPath(): string {
    return environment.hostPath;
  }

  public get openidConfigurationUrl(): string {
    return environment.openidConfigurationUrl;
  }

  public get redirectPenneo(): string {
    return environment.redirectPenneo;
  }

  public get baseHref(): string {
    return environment.baseHref;
  }

  public get primaryColor(): string {
    return environment.primaryColor;
  }

  public get secondaryColor(): string {
    return environment.secondaryColor;
  }

  public get tertiaryColor(): string {
    return environment.tertiaryColor;
  }

  public get bankNumber(): number {
    return environment.bankNumber;
  }

  public get bankName(): string {
    return environment.bankName;
  }

  public get mock(): boolean {
    return environment.mock;
  }

}
