import { Component, OnInit, Inject } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Patterns } from '../common/patterns.model';
import { Translations, TRANSLATIONS_TOKEN } from '../translations/translations';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'src/app/services/oauth/oauth.service';
import { Nav } from '../../utils/enums';
import { Router } from '@angular/router';
import { CprService } from 'src/app/services/cpr.service';
import { ToastConfig } from '@kirbydesign/designsystem';
import { ToastController } from '@kirbydesign/designsystem';

@Component({
  selector: 'app-cpr',
  templateUrl: './cpr.component.html',
  styleUrls: ['./cpr.component.scss'],
})
export class CprComponent implements OnInit {
  constructor(
    @Inject(TRANSLATIONS_TOKEN) public text: Translations,
    public patterns: Patterns,
    private translateService: TranslateService,
    private oAuthService: OAuthService,
    private router: Router,
    private cprService: CprService,
    public toastController: ToastController
  ) {}

  public formGroup = new FormGroup({
    cpr: new FormControl('', [
      Validators.required,
      Validators.pattern(this.patterns.cprPatternFull),
    ]),
  });

  public onCprConfirm() {
    const submittedCpr = this.getFormgroupControlById('cpr').value;
    const validated =
      this.validate(submittedCpr) && this.getFormgroupControlById('cpr').valid;
    if (validated) {
      this.setNewCprRelation(submittedCpr);
    } else {
      //Placeholder to show error if not validated
    }
  }

  private validate(cpr: string): boolean {
    //Placeholder to validate cpr if needed
    return true;
  }

  public getFormgroupControlById(id: string): AbstractControl {
    return this.formGroup.controls[id];
  }

  public get showErrorMessage(): string {
    const error =
      this.getFormgroupControlById('cpr').invalid &&
      this.getFormgroupControlById('cpr').touched;
    return error ? this.translateService.instant(this.text.cpr.error) : '';
  }

  private setNewCprRelation(cpr: string): boolean {
    this.cprService.setCprRelation(cpr).subscribe({
      next: (response) => {
        console.log('a-okay call setNewCprRelation');
        this.router.navigate([Nav.ORDERS]);
      },
      error: (error) => {
        const config: ToastConfig = {
          message: this.translateService.instant(this.text.cpr.relationError),
          messageType: 'warning',
          durationInMs: 4000,
        };
        this.toastController.showToast(config);
      },
    });
    return false;
  }

  ngOnInit(): void {
    const isLoggedIn = this.oAuthService.isLoggedIn;
    if (!isLoggedIn)  {

      this.router.navigate([Nav.LOGON]).then(value => {});
    }
  }
}
