/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import * as rs from 'jsrsasign';
// import rs from 'jsrsasign';

// @ts-ignore
export async function calcHash(valueToHash: string, algorithm: string): Promise<string> {
  const hashAlg = new rs.KJUR.crypto.MessageDigest({ alg: algorithm });
  const result = hashAlg.digestString(valueToHash);
  const byteArrayAsString = toByteArrayAsString(result);
  return Promise.resolve(byteArrayAsString);
}

function toByteArrayAsString(hexString: string): string {
  let result = '';
  for (let i = 0; i < hexString.length; i += 2) {
    const hexDigit = hexString.charAt(i) + hexString.charAt(i + 1);
    const num = parseInt(hexDigit, 16);
    result += String.fromCharCode(num);
  }
  return result;
}
