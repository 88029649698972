declare let window: any;

export class DynamicEnvironment {
  public get environment(): string {
    return window.config.environment;
  }

  public get xApiKey(): string {
    return window.config.xApiKey;
  }

  public get hostPath(): string {
    return window.config.hostPath;
  }

  public get openidConfigurationUrl(): string {
    return window.config.openidConfigurationUrl;
  }

  public get redirectPenneo(): string {
    return window.config.redirectPenneo;
  }

  public get baseHref(): string {
    return window.config.baseHref;
  }

  public get primaryColor(): string {
    return window.config.primaryColor;
  }

  public get secondaryColor(): string {
    return window.config.secondaryColor;
  }

  public get tertiaryColor(): string {
    return window.config.tertiaryColor;
  }

  public get signedColor(): string {
    return window.config.signedColor;
  }

  public get rejectedColor(): string {
    return window.config.rejectedColor;
  }

  public get bankNumber(): number {
    return window.config.bankNumber;
  }

  public get bankName(): string {
    return window.config.bankName;
  }

  public get mock(): boolean {
    return window.config.mock;
  }
}
