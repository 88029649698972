import { BrowserModule } from '@angular/platform-browser';
import { KirbyModule } from '@kirbydesign/designsystem';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppInitService } from './app-init.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateDSCLoader } from './modules/translations/translate-loader';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { ToolbarComponent } from './modules/toolbar/toolbar.component';
import { CprComponent } from './modules/cpr/cpr.component';
import { Patterns } from './modules/common/patterns.model';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TRANSLATIONS_TOKEN } from './modules/translations/translations';
import { generateTranslationKeys } from './modules/translations/translation-factory';
import { NgxMaskModule } from 'ngx-mask';
import { LogonComponent } from './modules/logon/logon.component';
import { OAuthService } from './services/oauth/oauth.service';
import { HttpClientModule } from '@angular/common/http';
import { RedirectComponent } from './modules/redirect/redirect.component';
import { OrdersComponent } from './modules/orders/orders.component';
import { OrderComponent } from './modules/order/order.component';
import { MessageComponent } from './modules/message/message.component';
import {DatePipe} from '@angular/common';

import { registerLocaleData } from '@angular/common';
import localeDaDK from '@angular/common/locales/da';

registerLocaleData(localeDaDK);

export function initApp(appLoadService: AppInitService) {
  return async () => appLoadService.init();
}

export function createTranslateLoader() {
  return new TranslateDSCLoader();
}

@NgModule({
  declarations: [
    AppComponent,
    ToolbarComponent,
    CprComponent,
    LogonComponent,
    RedirectComponent,
    OrdersComponent,
    OrderComponent,
    MessageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    KirbyModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
      },
    }),
    NgxMaskModule.forRoot(),
  ],
  providers: [
    AppInitService,
    Patterns,
    DatePipe,
    OAuthService,
    {
      provide: TRANSLATIONS_TOKEN,
      useFactory: generateTranslationKeys(),
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [AppInitService],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'da-DK' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
