export function b64DecodeUnicode(str: string) {
  // eslint-disable-next-line no-useless-escape
  const base64 = str.replace(/\-/g, '+').replace(/\_/g, '/');

  return decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join(''),
  );
}

export function base64UrlEncode(str: string): string {
  const base64 = btoa(str);
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
}
