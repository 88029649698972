import {Injectable, isDevMode, OnDestroy} from '@angular/core';
import { from, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

declare let window: any;

@Injectable()
export class AppInitService implements OnDestroy {
  private subscriptions: Subscription = new Subscription();

  constructor() {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public async init() {
    return from(
      Promise.all([
        fetch('assets/config/shared.app-config.json').then(async (response) =>
          response.json()
        ),
        fetch('assets/config/app-config.json').then(async (response) =>
          response.json()
        ),
      ]).then((config) => {
        for (const property in config[1]) {
          if (property != null) {
            config[0][property] = Object.assign(
              config[0][property],
              config[1][property]
            );
          }
        }
        return config[0];
      })
    )
      .pipe(
        map(async (appConfig) => {
          let config = appConfig;
          await this.parseConfig(appConfig)
            .then((parsedConfig) => {
              config = parsedConfig as any;
            })
            .catch(() => {});
          window.config = config;
          this.setupFavIcon(config.faviconUrl);
          this.setupTheme(config);
          return config;
        })
      )
      .toPromise();
  }

  private async parseConfig(appConfig: any): Promise<JSON> {
    const sharedConfig: JSON = appConfig.shared;
    const bankNumber: string = this.findCurrentBank();
    return Object.assign(sharedConfig, appConfig[bankNumber]);
  }

  private findCurrentBank(): string {
    const currentDomain = window.location.hostname;

    console.log(currentDomain)
    switch (currentDomain) {
      case 'underskriftsrum.sydbank.dk':
      case 'test-underskriftsrum.sydbank.dk':
        return '001';
      case 'underskriftsrum.nordfynsbank.dk':
      case 'test-underskriftsrum.nordfynsbank.dk':
        return '008';
      case 'underskriftsrum.skjernbank.dk':
      case 'test-underskriftsrum.skjernbank.dk':
        return '009';
      case 'underskriftsrum.djurslandsbank.dk':
      case 'test-underskriftsrum.djurslandsbank.dk':
        return '013';
      case 'underskriftsrum.kreditbanken.dk':
      case 'test-underskriftsrum.kreditbanken.dk':
        return '015';
      case 'underskriftsrum.landbobanken.dk':
      case 'test-underskriftsrum.landbobanken.dk':
        return '024';
      case 'underskriftsrum.spks.dk':
      case 'test-underskriftsrum.spks.dk':
        return '042';
      case 'signatur.sydbank.de':
      case 'test-signatur.sydbank.de':
        return '047';
      case 'underskriftsrum.jyskebank.dk':
      case 'test-underskriftsrum.jyskebank.dk':
        return '051';
      case 'underskriftsrum.bankdata.dk':
      case 'test-underskriftsrum.bankdata.dk':
        return '300';
      default:
        return this.getBankFromQueryParams();
    }
  }

  //Used for when app is called through test-underskriftsrum
  private getBankFromQueryParams(): string {

    const urlParams = new URLSearchParams(window.location.search);
    const bank =
      urlParams.get('bank') ?? sessionStorage.getItem('queryBankNumber');

    console.log("getBankFromQueryParams: " + bank)
    if (bank != null) {
      sessionStorage.setItem('queryBankNumber', bank);

      if(bank.toLowerCase() === "mock") {
        return bank;
      }

      return bank.padStart(3, '0');
    } else {


      if(isDevMode()) {
        sessionStorage.setItem('queryBankNumber', "mock")
        return "mock"
      }

      //Some sort of error needs to be displayed
      console.error(
        'Could not determine banknumber from query params. Is "bank" missing?'
      );
      return 'noBank';
    }
  }

  private setupFavIcon(faviconUrl: string) {
    const link: HTMLLinkElement =
      document.querySelector('link[rel*="icon"]') ??
      document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = faviconUrl;
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  private setupTheme(config: any): void {
    if (config.primaryColor != null) {
      document.documentElement.style.setProperty(
        '--kirby-primary',
        config.primaryColor
      );
    }
    if (config.secondaryColor != null) {
      document.documentElement.style.setProperty(
        '--kirby-secondary',
        config.secondaryColor
      );
    }
    if (config.tertiaryColor != null) {
      document.documentElement.style.setProperty(
        '--kirby-tertiary',
        config.tertiaryColor
      );
    }
    if (config.primaryContrastColor != null) {
      document.documentElement.style.setProperty(
        '--kirby-primary-contrast',
        config.primaryContrastColor
      );
    }
    if (config.signedColor != null) {
      document.documentElement.style.setProperty(
        '--kirby-success',
        config.signedColor
      );
    }
    if (config.rejectedColor != null) {
      document.documentElement.style.setProperty(
        '--kirby-warning',
        config.rejectedColor
      );
    }
  }
}
