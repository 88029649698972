import * as daTranslations from 'src/assets/i18n/da.json';

//Make json into a object.
export function generateTranslationKeys() {
  return () => {
    const data = daTranslations;
    const convertObjectToKeysObject = (object: any, prefix: string) => {
      const result: any = {};
      // eslint-disable-next-line guard-for-in
      for (const propertyName in object) {
        const subProperty = object[propertyName];
        if (typeof subProperty === 'string') {
          result[propertyName] = prefix + propertyName;
        } else {
          result[propertyName] = convertObjectToKeysObject(
            subProperty,
            prefix + propertyName + '.'
          );
        }
      }
      return result;
    };
    const translationsKeys = convertObjectToKeysObject(data, '');
    return translationsKeys.default;
  };
}
