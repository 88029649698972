import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PostMessageGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    this.postStatusMessage(route.queryParams.signed, route.queryParams.rejected);
    return true;
  }

  private postStatusMessage(signed: any, rejected: any) {
    if (window.opener) {
      if (signed) {
        console.log(`post status SIGNED`);
        window.opener.postMessage({status: "SIGNED"}, "*");
      }

      if (rejected) {
        console.log(`post status REJECTED`);
        window.opener.postMessage({status: "REJECTED"}, "*")
      }
    }
  }

}
