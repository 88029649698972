import {Component, OnInit, Inject} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Nav, LoginType} from '../../utils/enums';
import {Router} from '@angular/router';
import {OAuthService} from 'src/app/services/oauth/oauth.service';
import {Translations, TRANSLATIONS_TOKEN} from '../translations/translations';
import {TranslateService} from '@ngx-translate/core';
import {CprService} from 'src/app/services/cpr.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent implements OnInit {
  public error: any = null;
  public errorText: string = '';
  public validLogin: boolean = true;

  // CUR_CAN002	Du har afbrudt login.
  // CUR_CAN003	Sessionen er udløbet
  private readonly errorCodesNotToShow = ['CUR_CAN002', 'CUR_CAN003'];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private oAuthService: OAuthService,
    private cprService: CprService,
    public translateService: TranslateService,
    @Inject(TRANSLATIONS_TOKEN) public text: Translations
  ) {
  }

  ngOnInit() {
    const queryParams = this.route.snapshot.queryParams;
    this.errorText = this.translateService.instant(this.text.login.errorText);
    if (queryParams.error != null) {
      const description = queryParams.error_description.split('-')[1];

      this.error = JSON.parse(atob(description));

      if (this.errorCodesNotToShow.includes(this.error.Code)) {
        // user aborded login process
        this.router.navigate([Nav.LOGON]);
      } else this.validLogin = false;
      return;
    }
    this.fetchTokenFromCurity()
      .then(() => {
        this.determineLoginFlow();
      })
      .catch(() => {
        this.validLogin = false;
      });
  }

  private async fetchTokenFromCurity() {
    const loginStatus: boolean =
      await this.oAuthService.loadDiscoveryDocumentAndTryLogin();
    if (!loginStatus) {
      throw new Error('Login failed');
    }
  }

  private async determineLoginFlow() {
    const loginInfo = this.oAuthService.loginType;
    if (loginInfo === LoginType.PRIVATE) {
      this.handlePrivateLogin();
    } else if (loginInfo === LoginType.BUSINESS) {
      this.handleBusinessLogin();
    } else {
      this.validLogin = false;
    }
  }

  private handlePrivateLogin() {
    this.checkCprRelation().subscribe(hasRelation => {
      if (hasRelation) {
        console.log("redirect to " + Nav.ORDERS)
        this.router.navigate([Nav.ORDERS]).then(r => {
        });
      } else {
        console.log("redirect to " + Nav.CPR)
        this.router.navigate([Nav.CPR]).then(r => {
        });
      }
    })
  }

  private handleBusinessLogin() {
    this.router.navigate([Nav.ORDERS]).then(r => {
    });
  }

  private checkCprRelation(): Observable<boolean> {
    return this.cprService.getCprRelation();
  }
}
