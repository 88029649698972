<div class="cprContainer">
  <h1>{{ text.cpr.title | translate }}</h1>
  <div class="cprForm">
    <p class="kirby-text-small">{{ text.cpr.subtitle | translate }}</p>
    <form [formGroup]="formGroup">
      <kirby-form-field
        (keyup.enter)="onCprConfirm()"
        [message]="showErrorMessage"
      >
        <input
          kirby-input
          formControlName="cpr"
          [placeholder]="text.cpr.placeholder | translate"
          [hasError]="
            getFormgroupControlById('cpr').invalid &&
            getFormgroupControlById('cpr').touched
          "
          [mask]="patterns.cprMaskFull"
          [patterns]="patterns.cprPatterns"
        />
      </kirby-form-field>
    </form>
    <button kirby-button (click)="onCprConfirm()" [disabled]="!formGroup.valid">
      {{ text.cpr.button | translate }}
    </button>
  </div>
</div>
