import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'src/app/services/oauth/oauth.service';
import { Nav } from 'src/app/utils/enums';
import { Translations, TRANSLATIONS_TOKEN } from '../translations/translations';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit {
  @Input() message: string;
  @Input() subMessage: string = '';
  @Input() showLoginButton: boolean = false;
  @Input() backToLoginText: string = '';
  public loginText: string = '';

  constructor(
    @Inject(TRANSLATIONS_TOKEN) public text: Translations,
    private router: Router,
    private oAuthService: OAuthService,
    public translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.loginText =
      this.backToLoginText === ''
        ? this.translateService.instant(this.text.common.errorButton)
        : this.backToLoginText;
  }

  public onBackToLogon() {
    this.oAuthService.logout();
    this.router.navigate([Nav.LOGON]);
  }
}
