import { Component, OnInit, Inject } from '@angular/core';
import { Translations, TRANSLATIONS_TOKEN } from '../translations/translations';
import { EnvironmentService } from '../../../environments/environment.service';
import { Router } from '@angular/router';
import { OAuthService } from 'src/app/services/oauth/oauth.service';
import { Banks, LoginType, Nav } from '../../utils/enums';

@Component({
  selector: 'app-logon',
  templateUrl: './logon.component.html',
  styleUrls: ['./logon.component.scss'],
})
export class LogonComponent implements OnInit {
  public isWaitingForRedirectParam = true;
  public logoff = false;
  public get bankName() {
    return this.environmentService.bankName;
  }

  public get isBankAllowed(): boolean {
    return Object.values(Banks).includes(this.environmentService.bankNumber);
  }

  constructor(
    @Inject(TRANSLATIONS_TOKEN) public text: Translations,
    private environmentService: EnvironmentService,
    private router: Router,
    private oAuthService: OAuthService
  ) {

  }

  public async onPrivateLogin() {
    this.oAuthService.setLoginType(LoginType.PRIVATE);
    await this.initLogin();
  }

  public async onBusinessLogin() {
    this.oAuthService.setLoginType(LoginType.BUSINESS);
    await this.initLogin();
  }

  private async initLogin() {
    try {
      const loggedIn =
        await this.oAuthService.loadDiscoveryDocumentAndTryLogin();
      if (loggedIn === true) {
        const loginType = this.oAuthService.loginType;
        if (loginType === LoginType.BUSINESS)
          this.router.navigate([Nav.ORDERS]);
        else if (loginType === LoginType.PRIVATE)
          this.router.navigate([Nav.CPR]);
      } else {
        // Not logged in
        this.oAuthService.initCodeFlow();
      }
    } catch (error) {
      if (error.status !== 0) {
        //Insert error here
      }
    }
  }

  ngOnInit(): void {
    this.oAuthService.logout();
  }
}
