import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EnvironmentService} from '../../../environments/environment.service';
import {LoginType} from '../../utils/enums';
import {base64UrlEncode} from './helpers/b64_helpers';
import {calcHash} from './helpers/crypto';
import {WebHttpUrlEncodingCodec} from './helpers/encoder';
import {OAuthStorage, OidcDiscoveryDoc, TokenResponse} from './types';

@Injectable()
export class OAuthService {
  private storage: OAuthStorage;
  private issuer?: string;
  private loginUrl?: string;
  private logoutUrl?: string;
  private tokenEndpoint?: string;
  private readonly clientIdPrivate = 'underskriftsrum';
  private readonly clientIdBusiness = 'underskriftsrum_erhverv';
  private readonly clientIdGermany = 'signatur_sydbank_de';
  public static readonly REDIRECT_PATH = 'redirect';
  private scope = 'urum';
  private redirectUrl: string | null = null;
  private openidConfigurationUrl;

  private get clientId() {
    switch (this.loginType) {
      case LoginType.PRIVATE:
        if (this.environmentService.bankNumber === 47) {
          return this.clientIdGermany;
        }
        return this.clientIdPrivate;
      case LoginType.BUSINESS:
        return this.clientIdBusiness;
      default:
        return '';
    }
  }

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService
  ) {
    this.storage = sessionStorage;

    const base = document.getElementsByTagName('base')[0];
    const href = base.getAttribute('href');
    if (href != null) {
      this.redirectUrl = `${window.location.origin}${href}${OAuthService.REDIRECT_PATH}`;
    }
    this.openidConfigurationUrl =
      this.environmentService.openidConfigurationUrl;
  }

  public get loginType(): LoginType {
    const loginType = this.storage.getItem('loginType');
    switch (loginType) {
      case LoginType.PRIVATE:
        return LoginType.PRIVATE;
      case LoginType.BUSINESS:
        return LoginType.BUSINESS;
      default:
        return LoginType.UNDEFINED;
    }
  }

  public setLoginType(value: LoginType) {
    switch (value) {
      case LoginType.PRIVATE:
        this.storage.setItem('loginType', LoginType.PRIVATE);
        if (this.environmentService.bankNumber === 47) {
          this.storage.setItem('client_id', this.clientIdGermany);
        } else {
          this.storage.setItem('client_id', this.clientIdPrivate);
        }
        break;
      case LoginType.BUSINESS:
        this.storage.setItem('client_id', this.clientIdBusiness);
        this.storage.setItem('loginType', LoginType.BUSINESS);
        break;
      default:
        this.storage.setItem('client_id', '');
        this.storage.setItem('loginType', LoginType.UNDEFINED);
        break;
    }
  }

  public async loadDiscoveryDocumentAndTryLogin(): Promise<boolean> {
    await this.loadDiscoveryDocument(this.openidConfigurationUrl);
    return this.tryLoginCodeFlow();
  }

  public async loadDiscoveryDocument(fullUrl?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (fullUrl == null) {
        fullUrl = this.issuer ?? '';
        if (!fullUrl.endsWith('/')) {
          fullUrl += '/';
        }
        fullUrl += '.well-known/openid-configuration';
      }
      this.http.get<OidcDiscoveryDoc>(fullUrl).subscribe(
        (doc) => {
          this.loginUrl = doc.authorization_endpoint;
          this.logoutUrl = doc.end_session_endpoint || this.logoutUrl;
          this.issuer = doc.issuer;
          this.tokenEndpoint = doc.token_endpoint;
          resolve(doc);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  public initCodeFlow() {
    void this.createLoginUrl().then((url) => {
      location.href = url;
    });
  }

  public async tryLoginCodeFlow(): Promise<boolean> {

    const responseParams = new URLSearchParams(window.location.search);
    const code = responseParams.get('code');
    const state = responseParams.get('state');

    if (state == null) {
      return Promise.resolve(false);
    }

    const error = responseParams.get('error');
    if (error != null) {
      return Promise.reject(error);
    }

    const nonce = this.storage.getItem('nonce');
    if (state !== nonce && !this.environmentService.mock) {
      return Promise.reject(new Error('Request and response state not equal'));
    }

    const verifier = this.storage.getItem('PKCI_verifier');
    if (verifier == null) {
      return Promise.reject(new Error('No code verifier'));
    }
    if (code == null) {
      return Promise.reject(new Error('Code not set!'));
    }

    if (this.redirectUrl == null) {
      return Promise.reject(new Error('this.redirectUrl not set!'));
    }

    if (this.tokenEndpoint == null) {
      return Promise.reject(new Error('tokenEndpoint not defined'));
    }

    const tokenParams = new HttpParams({
      encoder: new WebHttpUrlEncodingCodec(),
    })
      .set('grant_type', 'authorization_code')
      .set('code', code)
      .set('code_verifier', verifier)
      .set('client_id', this.clientId)
      .set('redirect_uri', this.redirectUrl);
    return new Promise((resolve, reject) => {
      this.http.post<TokenResponse>(this.tokenEndpoint!, tokenParams
      ).subscribe(
        (response) => {
          this.storage.setItem('tokenresponse', JSON.stringify(response));
          resolve(true);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  public get tokenResponse(): TokenResponse {
    const tokenresponse = this.storage.getItem('tokenresponse');
    return tokenresponse != null ? JSON.parse(tokenresponse) : null;
  }

  private async createLoginUrl(): Promise<string> {
    const nonce = await this.createAndSaveNonce();
    const responseType = 'code';
    const [challenge, verifier] =
      await this.createChallengeVerifierPairForPKCE();
    this.storage.setItem('PKCI_verifier', verifier);

    const params = new URLSearchParams();
    params.append('response_type', responseType);
    params.append('client_id', this.clientId);
    params.append('state', nonce);
    if (this.redirectUrl != null) {
      params.append('redirect_uri', this.redirectUrl);
    }
    params.append('scope', this.scope);
    params.append('code_challenge', challenge);
    params.append('code_challenge_method', 'S256');
    params.append('prompt', 'login');

    if (this.loginUrl != null) {
      const separationChar = this.loginUrl.includes('?') ? '&' : '?';
      return this.loginUrl + separationChar + params.toString();
    }
    throw new Error(
      'Could not assemble loginUrl from openid-configuration.authorization_endpoint'
    );
  }

  private async createAndSaveNonce(): Promise<string> {
    return this.createNonce().then((nonce: any) => {
      this.storage.setItem('nonce', nonce);
      return nonce;
    });
  }

  private async createNonce(): Promise<string> {
    return new Promise((resolve) => {
      /*
       * This alphabet uses a-z A-Z 0-9 _- symbols.
       * Symbols order was changed for better gzip compression.
       */
      const url =
        'Uint8ArdomValuesObj012345679BCDEFGHIJKLMNPQRSTWXYZ_cfghkpqvwxyz-';
      let size = 45;
      let id = '';
      const bytes = crypto.getRandomValues(new Uint8Array(size));
      while (size > 0) {
        // tslint:disable:next no-bitwise
        // eslint-disable-next-line no-bitwise
        id += url[bytes[size] & 63];
        size -= 1;
      }

      resolve(id);
    });
  }

  private async createChallengeVerifierPairForPKCE(): Promise<
    [string, string]
  > {
    const verifier = await this.createNonce();
    const challengeRaw = await calcHash(verifier, 'sha-256');
    const challenge = base64UrlEncode(challengeRaw);

    return [challenge, verifier];
  }

  public get isLoggedIn(): boolean {
    if (this.tokenResponse == null || this.loginType === LoginType.UNDEFINED) {
      return false;
    } else {
      return this.tokenResponse.access_token ? true : false;
    }
  }

  public logout() {
    sessionStorage.removeItem('nonce');
    sessionStorage.removeItem('PKCI_verifier');
    sessionStorage.removeItem('tokenresponse');
    sessionStorage.removeItem('loginType');
    sessionStorage.removeItem('customerinfo');
    sessionStorage.removeItem('client_id');
  }
}
