import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class httpHeaderSetupService {
  private readonly PRAGMA_HEADER_FIELD: string = 'Pragma';
  private readonly X_API_KEY_HEADER_FIELD: string = 'x-api-key';
  private readonly CACHE_CONTROL_HEADER_FIELD: string = 'Cache-Control';
  private readonly CONTENT_TYPE_HEADER_FIELD: string = 'Content-type';
  private readonly AUTHORIZATION_FIELD: string = 'Authorization';

  public getHttpHeaderOptions(
    xApiKey: string,
    credential: string,
    token_type: string
  ): {
    headers: HttpHeaders;
    withCredentials: boolean;
    observe: 'response';
  } {
    return {
      headers: new HttpHeaders()
        .set(this.X_API_KEY_HEADER_FIELD, xApiKey)
        .set(this.PRAGMA_HEADER_FIELD, 'no-cache')
        .set(this.CACHE_CONTROL_HEADER_FIELD, 'no-cache')
        .set(this.CONTENT_TYPE_HEADER_FIELD, 'application/json')
        .set(this.AUTHORIZATION_FIELD, token_type + ' ' + credential),
      observe: 'response',
      withCredentials: true,
    };
  }
}
