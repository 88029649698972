import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {EnvironmentService} from 'src/environments/environment.service';
import {httpHeaderSetupService} from './http-header-setup.service';
import {Observable, of } from 'rxjs';
import {OAuthService} from 'src/app/services/oauth/oauth.service';
import {TokenResponse} from './oauth/types';

@Injectable({
  providedIn: 'root',
})
export class CprService {
  private readonly CPR_BASE_PATH: string = 'digital-signing-customer-app-sig/';
  private readonly CPR_SERVICE_PATH: string = 'v1/national-id-relation';

  constructor(
    private environmentService: EnvironmentService,
    private httpClient: HttpClient,
    private httpHeaderSetup: httpHeaderSetupService,
    private oAuthService: OAuthService
  ) {
  }

  public getCprRelation(): Observable<boolean> {
    const xApiKey = this.environmentService.xApiKey;
    const url =
      this.environmentService.hostPath +
      this.CPR_BASE_PATH +
      this.CPR_SERVICE_PATH;
    // authentication (jwt) required
    const credential: TokenResponse = this.oAuthService.tokenResponse;
    const headers = this.httpHeaderSetup.getHttpHeaderOptions(
      xApiKey,
      credential.access_token,
      credential.token_type
    );
    let hasCprRelation: boolean = false;

    return this.httpClient.get<boolean>(url, headers)
      .pipe(
        map((res) => {
          console.log('result, cpr service', res);
          if (res.status === 200 || res.status === 204) {
            hasCprRelation = true;
          }
          console.log('hasCprRelation, cpr service', hasCprRelation);
          return hasCprRelation;
        }),
        catchError((err) => {
          console.log(err.status)
          if (err.status === 403) {
           hasCprRelation = false;
            return of(hasCprRelation);
          }
          console.log('err, cpr service', err);
          return Observable.throw(err.statusText);
        })
      );
  }

  public setCprRelation(cpr: string): Observable<object> {
    const xApiKey = this.environmentService.xApiKey;
    const url =
      this.environmentService.hostPath +
      this.CPR_BASE_PATH +
      this.CPR_SERVICE_PATH;
    // authentication (jwt) required
    const credential: TokenResponse = this.oAuthService.tokenResponse;
    const headers = this.httpHeaderSetup.getHttpHeaderOptions(
      xApiKey,
      credential.access_token,
      credential.token_type
    );

    const cprInput = {
      cprNumber: cpr,
    };
    console.log('setCprRelation', cprInput, url);
    return this.httpClient.post<object>(url, cprInput, headers).pipe(
      map((res) => {
        console.log('setCprRelation res', res);
        return res.body;
      }),
      catchError((err) => {
        console.log('setCprRelation error', err);
        return Observable.throw(err.statusText);
      })
    );
  }
}
