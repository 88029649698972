import { TranslateLoader } from '@ngx-translate/core';
import { from, Observable, of } from 'rxjs';

export class TranslateDSCLoader implements TranslateLoader {
  public getTranslation(lang: string): Observable<any> {
    switch (lang) {
      case 'da':
        return from(import('src/assets/i18n/da.json'));
      default:
        return of(null);
    }
  }
}
